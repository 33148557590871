<template>
  <div style="background: var(--v-component-base) !important" class="pt-1">
      <v-row justify="end" class="mb-2 mt-1">
        <v-col cols="12" sm="12" md="8" lg="4" class="py-0">
          <el-input
            id="search"
            suffix-icon="el-icon-search"
            v-model="params.search"
            clearable
            placeholder="Search"
          >
            <template slot="prepend">
              <el-button @click="addGenset()"
                ><i class="el-icon-plus"></i
              ></el-button>
            </template>
          </el-input>
        </v-col>
      </v-row>
      <v-card flat style="overflow-y: scroll"> 
        <v-data-table
          height="72vh"
          id="gensetTable"
          style="cursor: pointer"
          :search="params.search"
          :calculate-widths="true"
          :items="gensets.data"
          :single-expand="true"
          :loading="loading"
          :headers="headers"
          @click:row="revealGenset"
          :options="{
            page: params.offset + 1,
            itemsPerPage: params.limit,
            pageStart: params.offset + 1,
            pageStop: Math.round(gensets.total / this.params.limit),
            pageCount: Math.round(gensets.total / this.params.limit),
            itemsLength: gensets.total
          }"
        >
        <template v-slot:[`item.assigned`]="{ item }">
          <v-icon v-if="item.transportUnitId" color="tertiary">check</v-icon>
          <v-icon v-if="!item.transportUnitId" color="primary">close</v-icon>
        </template>
        </v-data-table>
      </v-card>
          <!-- VIEW VEHICLE -->
    <v-dialog overlay-opacity="0.9"  v-model="gensetCard" width="40vw" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card flat height="auto">
        <v-toolbar flat dense >
          <v-toolbar-title>{{addNew ? 'Add Genset' : genset && genset.serialNumber ? genset.serialNumber : ''}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn v-if="editing" @click="saveGensetUpdate"  :loading="savingUpdate" icon>
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn v-if="addNew" @click="createGenset"  :loading="savingCreate" icon>
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn v-if="!editing && !addNew" @click="editing = true" icon>
            <v-icon>edit</v-icon>
          </v-btn>
          <v-btn v-if="editing && !addNew" :loading="loadingDelete" @click="deleteGenset()" icon color="danger">
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn v-if="editing && !addNew" @click="cancelEdit()" icon>
            <v-icon>edit_off</v-icon>
          </v-btn>
          <v-btn @click="closeGensetCard" icon>
            <v-icon color="red">close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <span style="text-align: center; display: block;">Required</span>
          <v-divider class="mb-3"></v-divider>
          <v-row class="d-flex justify-center mb-2">
            <v-col cols="12" sm="6">
              <!-- ADD NEW -->
              <v-text-field v-if="addNew" outlined dense hide-details label="Serial Number" persistent-placeholder placeholder="Enter Genset Serial Number"
              v-model="newGenset.serialNumber"/>
              <!-- View Selected -->
              <v-text-field v-if="!addNew" :readonly="!editing" outlined dense hide-details label="Serial Number" persistent-placeholder placeholder="Enter Genset Serial Number"
              v-model="genset.serialNumber"/>
            </v-col>
          </v-row>
          <span style="text-align: center; display: block;">Optional</span>
          <v-divider class="mb-3"></v-divider>
          <!-- ADD NEW -->
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="6" class="pb-1">
              <!-- ADD NEW -->
              <v-text-field v-if="addNew" outlined dense hide-details label="Alias" persistent-placeholder placeholder="Enter Alias, ex. 'Genset 1'"
              v-model="newGenset.alias"/>
              <!-- View Selected -->
              <v-text-field v-if="!addNew" :readonly="!editing" outlined dense hide-details label="Alias" persistent-placeholder placeholder="Enter Alias, ex. 'Genset 1'"
              v-model="genset.alias"/>
            </v-col>
          </v-row>
          <v-row v-if="addNew">
            <v-col cols="12" sm="6">
              <v-text-field outlined dense hide-details label="Type" persistent-placeholder placeholder="Enter Type" class="mb-3"
              v-model="newGenset.type"/>
              <v-text-field outlined dense hide-details label="Voltage" persistent-placeholder placeholder="Enter Voltage" class="mb-3"
              v-model="newGenset.voltage"/>
              <v-text-field outlined dense hide-details label="Fuel Type" persistent-placeholder placeholder="Enter Fuel Type" class="mb-3"
              v-model="newGenset.fuelType"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field outlined dense hide-details label="Manufacturer" persistent-placeholder placeholder="Enter Manufacturer" class="mb-3"
              v-model="newGenset.manufacturer"/>
              <v-text-field outlined dense hide-details label="Frequency" persistent-placeholder placeholder="Enter Frequency" class="mb-3"
              v-model="newGenset.frequency"/>
              <v-text-field outlined dense hide-details label="Fuel Tank Capacity" persistent-placeholder placeholder="Enter Fuel Tank Capacity" class="mb-3"
              v-model="newGenset.fuelTankCapacity"/>
            </v-col>
          </v-row>
          <!-- View Selected -->
          <v-row v-if="!addNew">
            <v-col cols="12" sm="6">
              <v-text-field :readonly="!editing" outlined dense hide-details label="Type" persistent-placeholder placeholder="Enter Type" class="mb-3"
              v-model="genset.type"/>
              <v-text-field :readonly="!editing" outlined dense hide-details label="Voltage" persistent-placeholder placeholder="Enter Voltage" class="mb-3"
              v-model="genset.voltage"/>
              <v-text-field :readonly="!editing" outlined dense hide-details label="Fuel Type" persistent-placeholder placeholder="Enter Fuel Type" class="mb-3"
              v-model="genset.fuelType"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field :readonly="!editing" outlined dense hide-details label="Manufacturer" persistent-placeholder placeholder="Enter Manufacturer" class="mb-3"
              v-model="genset.manufacturer"/>
              <v-text-field :readonly="!editing" outlined dense hide-details label="Frequency" persistent-placeholder placeholder="Enter Frequency" class="mb-3"
              v-model="genset.frequency"/>
              <v-text-field :readonly="!editing" outlined dense hide-details label="Fuel Tank Capacity" persistent-placeholder placeholder="Enter Fuel Tank Capacity" class="mb-3"
              v-model="genset.fuelTankCapacity"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
 </div>   
</template>

<script>
export default {
  data: () => ({
    loading: false,
    loadingDelete: false,
    gensetCard: false,
    savingUpdate: false,
    savingCreate: false,
    addNew: false,
    originalGenset: null,
    editing: false,
    genset: {},
    gensets: {
      date: [],
      total: 0
    },
    newGenset: {},
    headers: [
    {
        text: 'Alias',
        align: 'center',
        sortable: true,
        value: 'alias'
      },
      {
        text: 'Serial Number',
        align: 'center',
        sortable: true,
        value: 'serialNumber'
      },
      {
        text: 'Type',
        sortable: true,
        align: 'center',
        value: 'type'
      } , 
      {
        text: 'Assigned',
        align: 'center',
        sortable: true,
        value: 'assigned'
      },
      // {
      //   text: 'Voltage',
      //   sortable: true,
      //   align: 'center',
      //   value: 'voltage'
      // },
      // {
      //   text: 'Frequency',
      //   sortable: true,
      //   align: 'center',
      //   value: 'frequency'
      // },
      // {
      //   text: 'Prime',
      //   sortable: true,
      //   align: 'center',
      //   value: 'prime'
      // },
      // {
      //   text: 'Standby',
      //   sortable: true,
      //   align: 'center',
      //   value: 'standby'
      // },
      // {
      //   text: 'Make',
      //   sortable: true,
      //   align: 'center',
      //   value: 'make'
      // },
      // {
      //   text: 'Model',
      //   sortable: true,
      //   align: 'center',
      //   value: 'model'
      // },
      // {
      //   text: 'Manufacturer',
      //   sortable: true,
      //   align: 'center',
      //   value: 'manufacturer'
      // },
      // {
      //   text: 'Fuel Tank Capacity',
      //   sortable: true,
      //   align: 'center',
      //   value: 'fuelTankCapacity'
      // },
      // {
      //   text: 'Fuel Type',
      //   sortable: true,
      //   align: 'center',
      //   value: 'fuelType'
      // },
      // {
      //   text: 'Weight',
      //   sortable: true,
      //   align: 'center',
      //   value: 'weight'
      // },

    ],
    params: {
      search: null,
      limit: 12,
      offset: 0
    },
  }),
  async created() {
    this.getGensets()
  },
  methods: {
    async getGensets() {
      this.loading = true
      this.gensets = await this.$API.getGensets()
      this.loading = false
    },
    addGenset() {
      this.gensetCard = true
      this.addNew = true
    },
    revealGenset(genset) {
      this.originalGenset = JSON.parse(JSON.stringify(genset))
      this.gensetCard = true
      this.addNew = false
      this.genset = genset
    },
    closeGensetCard() {
      this.gensetCard = false
      this.addNew = false
      this.genset = {}
    },
    cancelEdit() {
      this.editing = false
      this.genset = JSON.parse(JSON.stringify(this.originalGenset))
    },
    async createGenset() {
      this.savingCreate = true
      let create = await this.$API.createGenset(this.newGenset)
      if(create && create !== 'Genset Exists') {
        this.gensets.data.push(create)
        this.gensets.total++
        this.$message({
          message: 'Genset Created',
          type: 'success'
        })
        this.gensetCard = false 
        this.newGenset = {}
      } else if (create === 'Genset Exists') {
        this.$message({
          message: 'A Genset with this serial Number already exists.',
          type: 'error'
        })
      }else {
        this.$message({
          message: 'There was an error attempting to create this genset. Please try again.',
          type: 'error'
        })
      }
      this.savingCreate = false
    },
    async saveGensetUpdate() {
      this.savingUpdate = true
      let update = await this.$API.updateGenset(this.genset)
      if(update) {
        this.$message({
          message: 'Genset Updated',
          type: 'success'
        })
        this.gensetCard = false 
        this.editing = false
      } else {
        this.$message({
          message: 'There was an error attempting to update this genset. Please try again.',
          type: 'error'
        })
      }
      this.savingUpdate = false
    },
    async deleteGenset() {
      this.loadingDelete = true
      let del = await this.$API.deleteGenset(this.genset.id)
      if(del) {
        this.$message({
          message: 'Genset Deleted',
          type: 'success'
        })
        this.gensetCard = false 
        this.editing = false
        this.gensets.data.splice(this.gensets.data.indexOf(this.genset), 1)
        this.gensets.total--
      } else {
        this.$message({
          message: 'There was an error attempting to delete this genset. Please try again.',
          type: 'error'
        })
      }
      this.loadingDelete = false
    },
  }
}
</script>


<style scoped>


</style>